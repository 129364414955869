import {
  SET_ACCOMODATION_OPTIONS,
  SET_ACCOMODATION_OPTION,
  LOAD_ACCOMODATION_OPTIONS,
  LOAD_ACCOMODATION_OPTION,
  SAVE_ACCOMODATION_OPTION,
  REMOVE_ACCOMODATION_OPTION, UPDATE_ACCOMODATION_OPTION
} from "../actions/accomodationOptions/types";

export const initialState = {
  isFetching: false,
  accomodationOptions: [],
  accomodationOptionDetail: {},
};

const accomodationOptions = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_ACCOMODATION_OPTION:
      return {
        ...state,
        accomodationOptionDetail: {
          ...state.accomodationOptionDetail,
          [action.field]: action.value,
        },
      };
    case SET_ACCOMODATION_OPTION:
      return {
        ...state,
        isFetching: false,
        accomodationOptionDetail: action.accomodationOptionDetail,
      };
    case SET_ACCOMODATION_OPTIONS:
      return {
        ...state,
        isFetching: false,
        accomodationOptions: action.accomodationOptions,
      };
    case SAVE_ACCOMODATION_OPTION:
      return { ...state, isFetching: action.isFetching || false };
    case REMOVE_ACCOMODATION_OPTION:
      return { ...state, isFetching: action.isFetching || false };
    default:
      return state;
  }
};

export default accomodationOptions;
