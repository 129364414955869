import React from "react";
import styles from "./style";
import { connect } from "react-redux";

const Loader = (props) => {
  const { isFetching } = props;
  const classes = styles();
  if (isFetching) {
    return (
      <div className={classes.loader}>
        <div className={classes.loaderBox}>
          <div className={classes.loaderSpinner} />
        </div>
      </div>
    );
  } else {
    return null;
  }
};

const mapStateToProps = (state) => {
  return { isFetching: state.app.isFetching };
};

export default connect(mapStateToProps, null)(Loader);
