import React, { Component } from "react";
import { connect } from "react-redux";
import styles from "./style";
import { switchOffline } from "../../redux/actions/app";
import withStyles from "react-jss";
import OfflineIcon from "../../assets/img/offline.svg";

class Offline extends Component {
  checkOnline = () => {
    if (navigator.onLine && this.props.isOffline) {
      this.props.doSwitchOffline();
      window.location.reload();
    }
  };

  componentDidMount = () => {
    this.checkOnline();
  };

  render() {
    const { classes, isOffline } = this.props;
    if (isOffline) {
      return (
        <div className={classes.modal}>
          <img src={OfflineIcon} alt="offline" />
          <h5 variant="h5" className={classes.title}>
            Verbinding verbroken
          </h5>
          <p className={classes.disconnected}>
            Ooops... het ziet er naar uit dat je geen verbinding hebt.
            <br />
            Controleer je netwerkverbinding en probeer opnieuw te connecteren.
          </p>
          <button onClick={this.checkOnline}>Probeer opnieuw</button>
        </div>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state) => {
  return { isOffline: state.app.isOffline };
};

const mapDispatchToProps = (dispatch) => {
  return {
    doSwitchOffline: () => {
      dispatch(switchOffline());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles)(Offline));
