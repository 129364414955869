class Settings {
  constructor() {
    this._currentSettings = null;
  }

  setSettings(settings) {
    this._currentSettings = settings;
  }

  getSettings() {
    return this._currentSettings || {};
  }
}

const settings = new Settings();

export default settings;
