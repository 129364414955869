import {
  UPDATE_BOOKING,
  SEARCH_ACCOMODATIONS,
  SET_BOOKING_ACCOMODATIONS,
  CHECKOUT_BOOKING,
  NEXT_CHECKOUT_STEP,
  START_PAYMENT,
  LOAD_BOOKING,
  RESET_BOOKING,
  LOAD_BOOKING_PAYMENT,
  LOAD_BOOKINGS,
  SET_BOOKINGS,
  SET_BOOKINGDETAIL,
  SET_CALENDARITEMS, UPDATE_DETAIL_BOOKING,
} from "../actions/booking/types";

export const initialState = {
  isFetching: false,
  bookingAccomodations: [],
  accomodationOptions: [],
  calendarItems: undefined,
  booking: undefined,
  checkingOut: false,
  checkoutStep: 0,
  bookings: [],
  bookingDetail: undefined,
};

const booking = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_BOOKING:
     /* return {
        ...state,
        bookingDetail: {
          ...state.bookingDetail,
          [action.field]: action.value,
        },
      };*/
      return {
        ...state,
        booking: action.booking,
      };
    case UPDATE_DETAIL_BOOKING:
      return {
        ...state,
        bookingDetail: {
          ...state.bookingDetail,
          [action.field]: action.value,
        },
      }
    case SET_CALENDARITEMS:
      return {
        ...state,
        calendarItems: action.calendarItems,
      };
    case LOAD_BOOKING_PAYMENT:
      return {
        ...state,
        booking: action.booking,
        checkoutStep: 1,
        checkingOut: true,
      };
    case CHECKOUT_BOOKING:
      return {
        ...state,
        checkingOut: true,
        checkoutStep: 0,
      };
    case NEXT_CHECKOUT_STEP:
      return {
        ...state,
        booking: action.booking,
        checkoutStep: state.checkoutStep + 1,
      };
    case SET_BOOKING_ACCOMODATIONS:
      return {
        ...state,
        isFetching: false,
        bookingAccomodations: action.accomodations,
        calendarItems: undefined,
      };
    case SEARCH_ACCOMODATIONS:
      return {
        ...state,
        isFetching: action.isFetching || false,
        booking: undefined,
        calendarItems: undefined,
        accomodationOptions: [],
      };
    case START_PAYMENT:
      return {
        ...state,
        isFetching: action.isFetching || false,
      };
    case LOAD_BOOKING:
      return {
        ...state,
        isFetching: action.isFetching || false,
      };
    case LOAD_BOOKINGS:
      return {
        ...state,
        isFetching: action.isFetching || false,
      };
    case SET_BOOKINGS:
      return {
        ...state,
        isFetching: false,
        bookings: action.bookings,
      };
    case SET_BOOKINGDETAIL:
      return {
        ...state,
        isFetching: false,
        bookingDetail: action.bookingDetail,
      };
    case RESET_BOOKING:
      return {
        ...state,
        checkoutStep: 0,
        checkingOut: false,
        booking: undefined,
        bookingAccomodations: [],
        calendarItems: undefined,
      };
    default:
      return state;
  }
};

export default booking;
