import { AUTH_USER, GET_USERDETAIL, AUTH_ADMIN } from "../actions/auth/types";

const initialState = {
  authenticated: false,
  user: undefined,
};

const auth = (state = initialState, action) => {
  switch (action.type) {
    case AUTH_USER:
      return { ...state, authenticated: true, user: action.user };
    case GET_USERDETAIL:
      return { ...state, user: action.user || undefined };
    default:
      return state;
  }
};

export default auth;
