import nl_NL from "../i18n/nl.js";
import en from "../i18n/en.js";
import Translate from "../translate/Translate";

const nlLocale = Translate.createLocale("nl", "Nederlands", "NL", nl_NL);
const enLocale = Translate.createLocale("en", "English", "EN", en);

const createLocales = () => {
  return [nlLocale, enLocale];
};

const i18n = {
  createLocales: () => createLocales(),
};

export default i18n;
