export const setRefreshToken = (jwt) => {
  localStorage.setItem("refreshToken", jwt);
};

export const getRefreshToken = () => {
  return localStorage.getItem("refreshToken") || undefined;
};

export const setToken = (jwt) => {
  localStorage.setItem("jwtToken", jwt);
};

export const getToken = () => {
  return localStorage.getItem("jwtToken") || undefined;
};

export const clearTokens = () => {
  localStorage.removeItem("jwt");
  localStorage.removeItem("jwtToken");
  localStorage.removeItem("refreshToken");
};

export const clear = () => {
  clearTokens();
};
