import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import reduxThunk from "redux-thunk";
import { createStore, applyMiddleware, compose } from "redux";
import { Provider } from "react-redux";
import reducers from "./redux/reducers";
import Translate from "./translate/Translate";
import i18n from "./config/i18n";
import TagManager from "react-gtm-module";

const tagManagerArgs = {
  gtmId: "GTM-M9W43JZ",
};
TagManager.initialize(tagManagerArgs);

Translate.setLocales(i18n.createLocales());
Translate.setLocale("en");

const composeEnhancers =
  process.env.NODE_ENV === "development"
    ? window["__REDUX_DEVTOOLS_EXTENSION_COMPOSE__"] || compose
    : (r) => r;
const store = createStore(
  reducers,
  composeEnhancers(applyMiddleware(reduxThunk)),
);

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </BrowserRouter>
  </Provider>,
  document.getElementById("root"),
);

serviceWorkerRegistration.unregister();
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
/*if ("serviceWorker" in navigator) {
  serviceWorkerRegistration.register();
} else {
  serviceWorkerRegistration.unregister();
}*/

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
