import {
  START_FETCHING,
  END_FETCHING,
  CHANGE_LOCALE,
  CLEAR_LOCALE,
  SHOW_SNACK,
  HIDE_SNACK,
  END_OFFLINE,
  START_OFFLINE,
  LOAD_REDUCERS,
} from "./types";
import Translate from "../../../translate/Translate";

export function startFetching() {
  return {
    type: START_FETCHING,
  };
}

export function endFetching() {
  return {
    type: END_FETCHING,
  };
}

export function switchOffline() {
  return {
    type: navigator.onLine ? END_OFFLINE : START_OFFLINE,
  };
}

export function changeLocale(i18n) {
  return (dispatch) => {
    localStorage.setItem("currentLocale", i18n);
    Translate.setI18n(i18n);
    dispatch({
      type: CHANGE_LOCALE,
      i18n: i18n,
    });
    return Promise.resolve();
  };
}

export function loadLocale() {
  let i18n = undefined;
  let change = false;
  if (localStorage && localStorage.getItem("currentLocale")) {
    i18n = localStorage.getItem("currentLocale");
    if (i18n !== Translate.getCurrentLocale()) {
      Translate.setI18n(i18n);
      change = true;
    }
  }
  if (change) {
    return {
      type: CHANGE_LOCALE,
      i18n: i18n,
    };
  }
  return {
    type: CHANGE_LOCALE,
  };
}

export function clearLocale() {
  localStorage.removeItem("currentLocale");
  return {
    type: CLEAR_LOCALE,
  };
}

export function showSnack(message, messageType, delay, i18n) {
  return {
    type: SHOW_SNACK,
    message,
    messageType,
    delay,
    i18n,
  };
}

export function hideSnack() {
  return {
    type: HIDE_SNACK,
  };
}

export function loadReducers() {
  return {
    type: LOAD_REDUCERS,
  };
}
