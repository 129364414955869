const styles = {
  modal: {
    height: "100%",
    width: "100%",
    position: "fixed",
    top: 0,
    left: 0,
    backgroundColor: "#f7f7f7",
    zIndex: 9970,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",

    "& button": {
      padding: 20,
      backgroundColor: "#516de5",
      color: "white",
      border: "none",
      minWidth: 200,
    },
  },
  disconnected: {
    margin: 10,
    marginBottom: 20,
    whiteSpace: "pre-wrap",
    textAlign: "center",
  },
  title: {
    margin: 10,
    marginTop: 30,
    marginBottom: 20,
    fontSize: 22,
  },
};

export default styles;
