const translations = {
  search: "Search",
  checkInDate: "Check-in date",
  checkOutDate: "Check-out date",
  stayOfNights: "Stay of %nights% night(s)",
  companyPeople: "Your party",
  adults: "adult(s)",
  children: "child(ren)",
  searchAccomodations: "Search for accommodations",
  noAccomodationsFound: "No accommodations found.",
  searchAccomodationsFunction: "Search for accommodations with the search box.",
  login: "Sign in",
  notAllFieldsEntered: "Not all fields are entered",
  maximumPeopleError:
    "The total number of people in your booking exceeds the maximum allowed occupancy. Maximum persons allowed in villa : ",
  problemWhileSearching: "Problem while searching",
  maximumNights: "Maximum number of nights: ",
  minimumNights: "Minimum number of nights: ",
  checkOutHigherThan: "Check-out date must be higher than",
  dateHigherThan: "Date must be higher than or equal to",
  firstName: "Firstname",
  lastName: "Name",
  email: "Email",
  tel: "Telephone/Mobile",
  street: "Street",
  houseNumber: "Housenumber",
  city: "City",
  postalCode: "Postal code",
  country: "Country",
  remarks: "Remarks",
  next: "Next",
  addToBooking: "Add to booking",
  continue: "Continue",
  checkInOn: "Check-in on",
  checkOutOn: "Check-out on",
  nights: "night(s)",
  accomodation: "Accommodation",
  total: "Total",
  totalPrice: "Total price",
  serviceFee: "Service fee",
  advance: "Deposit",
  confirmBooking: "Confirm booking",
  myBooking: "My booking",
  addedToBooking: "Added to booking",
  entireHouse: "Entire house",
  appartement: "Appartement",
  room: "Room",
  accomodationFound: "Accommodation found",
  accomodationInBooking: "Accommodation in current booking",
  notAvailableOnData: "Unavailable for your selected dates",
  averagePricePerNight: "Average price per night",
  priceForNights: "Price for %nights% night(s)",
  yourData: "Your details",
  emailInvalid: "Emailaddress invalid",
  notAvailableForSearch:
    "No accommodations were found for your search criteria",
  guarantee: "Guarantee",
  priceToPay: "Amount due",
  bookingSummary: "Summary of your booking",
  bookingPaymentInformationGuarantee: (
    <>
      <strong>Your guarantee</strong>
      <br />
      If a guarantee is required on your booking, it will be refunded within 7
      days after leaving the property in perfect condition.
    </>
  ),
  bookingPaymentInformationDeposit: (
    <>
      <strong>Your deposit</strong>
      <br />
      You were asked to pay a deposit when creating your booking. 30 days before
      your departure you will be invited to pay for the remaining total.
    </>
  ),
  bookingPaymentInformationCancels: (
    <>
      <strong>Cancellation policy</strong>
      <br />
      Up to 14 days before arrival : 50% of total booking fee
      <br />
      Within 7 days of arrival you will be charged the full amount
    </>
  ),
  bookingPaymentInformationPayment: (
    <>
      You can continue the payment by selecting one of the different payment
      options below. After a successful payment you will be routed to a
      confirmation page and you will receive a confirmation mail shortly after.
      Please hold on to this mail. If you have any more questions, please send
      them to info@casaherta.com.
    </>
  ),
  bookingCheckInCheckOut: (
    <>
      <strong>Check-in is possible after 15:00 (3 pm) and check-out before 12:00 (12 am)</strong>
    </>
  ),
  continueWithPayment: "Continue with payment",
  error: "Error",
  errorWhilePaying: "Errow while creating payment",
  waitingForPayment: (
    <>
      Waiting for payment.
      <br />
      Please do not close this window or refresh the page.
    </>
  ),
  showAccomodationCalendar: "Show availability calendar",
  next: "Next",
  previous: "Previous",
  januari: "January",
  february: "February",
  maart: "March",
  april: "April",
  mei: "May",
  juni: "June",
  juli: "July",
  augustus: "August",
  september: "September",
  oktober: "October",
  november: "November",
  december: "December",
  available: "Available",
  unavailable: "Unavailable",
  onrequest: "On request",
  booked: "Booked",
  cleaningFee: "Cleaning fee",
  duenow: "Due now",
  dueon: "Due before",
  priceAccomodation: "Price accomodation",
  notEnableNights: "Not all dates in your selection are available"
};

export default translations;
