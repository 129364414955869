import decode from "jwt-decode";
import {
  setToken,
  getToken,
  clearTokens,
  getRefreshToken,
} from "../localstorage/auth";

export const onSignIn = (token) => {
  if (token) setToken(token);
};

export const onRefreshedToken = (token) => {
  setToken(token);
};

export const convertTokenToUser = (token) => {
  if (token) {
    const decoded = decode(token);
    let u = decoded && decoded.user;
    return u;
  }
};

export const getTokenUser = () => {
  const token = getJWTToken();
  return convertTokenToUser(token);
};

export const onSignOut = () => {
  clearTokens();
};

export const getJWTRefreshToken = () => {
  return getRefreshToken();
};

export const getJWTToken = () => {
  return getToken();
};

const getTokenExpirationDate = (encodedToken) => {
  const token = decode(encodedToken);
  if (!token.exp) {
    return undefined;
  }
  const date = new Date(0);
  date.setUTCSeconds(token.exp);
  return date;
};

const isTokenExpired = (token) => {
  const expirationDate = getTokenExpirationDate(token);
  return expirationDate ? expirationDate < new Date() : true;
};

export const expiredNeedsRefreshToken = () => {
  const refreshToken = getJWTRefreshToken();
  if (!refreshToken) return undefined;
  const token = getJWTToken();
  return token && isTokenExpired(token) ? refreshToken : undefined;
};

export const isSignedIn = () => {
  let token = getJWTToken();
  const isSigned = !!token && !isTokenExpired(token);
  return isSigned;
};

export const isSignedInAsAdmin = () => {
  let token = getJWTToken();
  const isSigned = !!token && !isTokenExpired(token);
  if (!isSigned) return false;
  const user = convertTokenToUser(token);
  return user.isAdmin || false;
};

export const requireAuth = (nextState, replace) => {
  if (!isSignedIn()) {
    replace({ pathname: "/signin" });
  }
};
