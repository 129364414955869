import React from "react";
import { connect } from "react-redux";
import CustomSnack from "../CustomSnack";
import { hideSnack } from "../../../redux/actions/app";
import Translate from "../../../translate/Translate";

const Snack = (props) => {
  const { message, messageType, delay, disableI18n } = props;
  return (
    <CustomSnack
      variant={messageType || "error"}
      message={
        message
          ? disableI18n
            ? message
            : Translate.translate(message)
          : undefined
      }
      delay={delay}
      onClose={() => props.doHideSnack()}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    message: state.app.snack.message,
    messageType: state.app.snack.messageType,
    delay: state.app.snack.delay,
    disableI18n: state.app.snack.i18n,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    doHideSnack: () => {
      dispatch(hideSnack());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Snack);
