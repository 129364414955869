const translations = {
  search: "Zoek",
  checkInDate: "Incheckdatum",
  checkOutDate: "Uitcheckdatum",
  stayOfNights: "Verblijf van %nights% nacht(en)",
  companyPeople: "Gezelschap",
  adults: "volwassene(n)",
  children: "kind(eren)",
  searchAccomodations: "Zoek naar accomodaties",
  noAccomodationsFound: "Geen accomodaties gevonden.",
  searchAccomodationsFunction: "Zoek naar accomodaties met de zoekfunctie.",
  login: "Aanmelden",
  notAllFieldsEntered: "Niet alle velden ingevuld",
  problemWhileSearching: "Probleem bij zoeken",
  maximumNights: "Maximum aantal nachten: ",
  minimumNights: "Minimum aantal nachten: ",
  checkOutHigherThan: "Uitcheck datum moet hoger zijn dan",
  dateHigherThan: "Datum moet hoger of gelijk zijn dan",
  firstName: "Voornaam",
  lastName: "Naam",
  email: "E-mail",
  tel: "Telefoon/GSM",
  street: "Straat",
  houseNumber: "Huisnummer",
  city: "Woonplaast",
  postalCode: "Postcode",
  country: "Land",
  remarks: "Opmerkingen",
  next: "Verder",
  addToBooking: "Toevoegen aan boeking",
  checkInOn: "Inchecken op",
  checkOutOn: "Uitchecken op",
  nights: "nacht(en)",
  accomodation: "Accommodatie",
  total: "Totaal",
  totalPrice: "Totale prijs",
  serviceFee: "Service fee",
  advance: "Voorschot",
  confirmBooking: "Boeking bevestigen",
  myBooking: "Mijn boeking",
  addedToBooking: "Toegevoegd aan boeking",
  entireHouse: "Gehele woning",
  appartement: "Appartement",
  room: "Kamer",
  accomodationFound: "Accommodatie gevonden",
  accomodationInBooking: "Accommodatie in huidige boeking",
  notAvailableOnData: "Niet beschikbaar op uw geselecteerde data",
  averagePricePerNight: "Gemiddelde prijs per nacht",
  priceForNights: "Prijs voor %nights% nacht(en)",
  yourData: "Uw gegevens",
  emailInvalid: "E-mailadres niet geldig",
  notAvailableForSearch:
    "Er werden geen accommodaties gevonden voor uw zoekcriteria",
  guarantee: "Waarborg",
  priceToPay: "Vandaag te voldoen",
  bookingSummary: "Samenvatting van jouw boeking",
  bookingPaymentInformationGuarantee: (
    <>
      <strong>Uw waarborg</strong>
      <br />
      Indien een waarborg werd aangerekend zal die na maximaal 7 dagen
      teruggestort worden indien u het goed perfect heeft achtergelaten.
    </>
  ),
  bookingPaymentInformationDeposit: (
    <>
      <strong>Uw voorschot</strong>
      <br />U betaalt nu een voorschot op uw boeking. Maximaal 30 dagen voor
      vetrek zal u uitgenodigd worden om het resterende bedrag te betalen.
    </>
  ),
  bookingPaymentInformationCancels: (
    <>
      <strong>Annuleren</strong>
      <br />U kan kosteloos annuleren tot 14 dagen voor uw verblijf. Tussen 14
      en 7 dagen voor uw verblijf zal u 50% aangerekend worden van de totale
      prijs verminderd met de eventuele waarborg. Indien u wil annuleren in
      minder dan 7 dagen voor vertrek zal u het ganse bedrag verschuldigd zijn.
    </>
  ),
  bookingPaymentInformationPayment: (
    <>
      Indien u verder gaat met de betaling zal u de verschillende betaalopties
      aangeboden krijgen. Na het succesvol betalen zal u doorgeleid worden naar
      een bevestigingspagina en ontvangt u enkele ogenblikken later een
      bevestigingsmail. Hou deze goed bij. Mocht u nog vragen hebben gelieve ons
      dan te contacteren op info@casaherta.com.
    </>
  ),
  bookingCheckInCheckOut: (
    <>
      <strong>Inchecken is mogelijk na 15.00 uur en uitchecken voor 12.00 uur</strong>
    </>
  ),
  continueWithPayment: "Verder gaan met betalen",
  error: "Probleem",
  errorWhilePaying: "Er is een probleem opgetreden tijdens aanmaken betaling",
  waitingForPayment: (
    <>
      Bezig met wachten op betaling.
      <br />
      Gelieve dit venster niet te sluiten of op refresh te drukken
    </>
  ),
  showAccomodationCalendar: "Toon kalender beschikbare data",
  next: "Volgende",
  previous: "Vorige",
  januari: "Januari",
  february: "Februari",
  maart: "Maart",
  april: "April",
  mei: "Mei",
  juni: "Juni",
  juli: "Juli",
  augustus: "Augustus",
  september: "September",
  oktober: "Oktober",
  november: "November",
  december: "December",
  available: "Beschikbaar",
  unavailable: "Onbeschikbaar",
  onrequest: "Op aanvraag",
  booked: "Geboekt",
  cleaningFee: "Schoonmaakkosten",
  duenow: "Vandaag voldoen",
  dueon: "Betalen voor",
  priceAccomodation: "Price accomodation",
  continue: "Continue",
  notEnableNights: "Not all dates in your selection are available"
};

export default translations;
