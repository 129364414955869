export const UPDATE_BOOKING = "UPDATE_BOOKING";
export const SET_BOOKING_ACCOMODATIONS = "SET_BOOKING_ACCOMODATIONS";
export const SEARCH_ACCOMODATIONS = "SEARCH_ACCOMODATIONS";
export const CHECKOUT_BOOKING = "CHECKOUT_BOOKING";
export const NEXT_CHECKOUT_STEP = "NEXT_CHECKOUT_STEP";
export const START_PAYMENT = "START_PAYMENT";
export const LOAD_BOOKING = "LOAD_BOOKING";
export const RESET_BOOKING = "RESET_BOOKING";
export const LOAD_BOOKING_PAYMENT = "LOAD_BOOKING_PAYMENT";
export const SAVE_BOOKING = "SAVE_BOOKING";
export const LOAD_BOOKINGS = "LOAD_BOOKINGS";
export const SET_BOOKINGS = "SET_BOOKINGS";
export const SET_BOOKINGDETAIL = "SET_BOOKINGDETAIL";
export const REMOVE_BOOKING = "REMOVE_BOOKING";
export const SET_CALENDARITEMS = "SET_CALENDARITEMS";
export const UPDATE_DETAIL_BOOKING = "UPDATE_DETAIL_BOOKING";
export const SEND_MAIL_REMINDER_PAYMENT = "SEND_MAIL_REMINDER_PAYMENT";
