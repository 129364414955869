import {
    SET_SUBSCRIBERS,
    LOAD_SUBSCRIBERS,
} from "../actions/subscribers/types";

export const initialState = {
    isFetching: false,
    subscribers: [],
    subscriberDetail: {},
};

const subscribers = (state = initialState, action) => {
    switch (action.type) {
        case SET_SUBSCRIBERS:
            return {
                ...state,
                isFetching: false,
                subscribers: action.subscribers,
            };
        case LOAD_SUBSCRIBERS:
            return { ...state, isFetching: action.isFetching || false };
        default:
            return state;
    }
};

export default subscribers;
