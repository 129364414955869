import React, { Suspense, lazy, Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Loader from "./components/Loader";
import Offline from "./components/Offline";
import Snack from "./components/snackbar/Snack";
import { loadSettings } from "./redux/actions/settings";
import { connect } from "react-redux";
import smoothscroll from "smoothscroll-polyfill";
smoothscroll.polyfill();

const Admin = lazy(() => import("./pages/admin/Admin"));
const Home = lazy(() => import("./pages/front/Home"));
class App extends Component {
  componentDidMount = () => {
    this.props.doLoadSettings();
  };

  render() {
    const { settings } = this.props;
    return (
      <Router>
        <Suspense fallback={<Loader isFetching={true} />}>
          {settings && (
            <Switch>
              <Route path="/admin">
                <Admin />
              </Route>
              <Route
                exact
                path="/:checkindate/:checkoutdate/:adults/:children"
                render={(props) => <Home {...props} />}
              />
              <Route path="/">
                <Home />
              </Route>
            </Switch>
          )}
          <Offline />
          <Snack />
          <Loader />
        </Suspense>
      </Router>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    settings: state.settings.settings,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    doLoadSettings: () => {
      dispatch(loadSettings());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
