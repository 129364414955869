import {
  UPDATE_SETTING,
  SET_SETTINGS,
  LOAD_SETTINGS,
  SAVE_SETTINGS,
} from "../actions/settings/types";

export const initialState = {
  isFetching: false,
  settings: undefined,
};

const settings = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_SETTING:
      return {
        ...state,
        settings: { ...state.settings, [action.field]: action.value },
      };
    case SET_SETTINGS:
      return { ...state, isFetching: false, settings: action.settings };
    case SAVE_SETTINGS:
      return { ...state, isFetching: action.isFetching || false };
    case LOAD_SETTINGS:
      return { ...state, isFetching: action.isFetching || false };
    default:
      return state;
  }
};

export default settings;
