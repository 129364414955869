import fetchAxios from "../../middlewares/axios";
import {
  SET_SETTINGS,
  SAVE_SETTINGS,
  LOAD_SETTINGS,
  UPDATE_SETTING,
} from "./types";
import { ROOT_URL_ANONYMOUS2, ROOT_URL_PROTECTED } from "../../../config/axios";
import Settings from "../../../settings/Settings";

export const loadSettings = () => {
  return (dispatch) => {
    dispatch({ type: LOAD_SETTINGS, isFetching: true });
    return new Promise(function (resolve, reject) {
      fetchAxios(dispatch, "get", `${ROOT_URL_ANONYMOUS2}/settings/`)
        .then((response) => {
          if (
            response &&
            response.data &&
            response.data.success &&
            response.data.settings
          ) {
            Settings.setSettings(response.data.settings);
            dispatch({
              type: SET_SETTINGS,
              settings: response.data.settings,
            });
            resolve(response);
          } else {
            reject("LOADERROR");
          }
        })
        .catch((e) => {
          reject({ error: e });
        });
    });
  };
};

export const saveSettings = () => {
  return (dispatch, getState) => {
    const { settings } = getState();
    dispatch({ type: SAVE_SETTINGS, isFetching: true });
    return new Promise(function (resolve, reject) {
      fetchAxios(
        dispatch,
        "post",
        `${ROOT_URL_PROTECTED}/settings/update`,
        {
          settings: settings.settings,
        },
        null,
        true,
      )
        .then((response) => {
          if (
            response &&
            response.data &&
            response.data.success &&
            response.data.settings
          ) {
            dispatch({
              type: SET_SETTINGS,
              settings: response.data.settings,
            });
            resolve(response);
          } else {
            reject("SAVEERROR");
          }
        })
        .catch((e) => {
          reject({ error: e });
        });
    });
  };
};

export const updateSetting = (field, value) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_SETTING,
      field,
      value,
    });
  };
};
