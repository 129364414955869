import { createUseStyles } from "react-jss";

const styles = createUseStyles({
  snackbar: {
    position: "fixed",
    top: 20,
    right: 20,
    borderRadius: 6,
    padding: 20,
    width: 260,
    backgroundColor: "#6c757d",
    color: "white",
    transition: "all 0.5s",
    visibility: "hidden",

    "&.error": {
      backgroundColor: "#dc3545",
    },

    "&.success": {
      backgroundColor: "#28a745",
    },

    "&.info": {
      backgroundColor: "#17a2b8",
    },

    "&.warning": {
      backgroundColor: "#ffc107",
    },

    "&.showing": {
      visibility: "visible",
      transform: "translateY(0)",
    },

    "&.hidden": {
      visibility: "hidden",
      transform: "translateY(-200px)",
    },
  },
});

export default styles;
