export const ROOT_URL_ANONYMOUS =
  "https://smbookings-staging.herokuapp.com/api/anonymous";
export const ROOT_URL_PROTECTED =
  "https://smbookings-staging.herokuapp.com/api/protected";
export const ROOT_URL_ANONYMOUS2 =
  "https://smbookings-staging.herokuapp.com/api/anonymous";
// export const ROOT_URL_ANONYMOUS = "http://localhost:3100/api/anonymous";
// export const ROOT_URL_PROTECTED = "http://localhost:3100/api/protected";
// export const ROOT_URL_ANONYMOUS2 = "http://localhost:3100/api/anonymous";

