export const UPDATE_ACCOMODATION = "UPDATE_ACCOMODATION";
export const SET_ACCOMODATION = "SET_ACCOMODATION";
export const SET_ACCOMODATIONS = "SET_ACCOMODATIONS";
export const LOAD_ACCOMODATIONS = "LOAD_ACCOMODATIONS";
export const LOAD_ACCOMODATION = "LOAD_ACCOMODATION";
export const SAVE_ACCOMODATION = "SAVE_ACCOMODATION";
export const SAVE_ACCOMODATION_PLANNING = "SAVE_ACCOMODATION_PLANNING";
export const LOAD_ACCOMODATION_PLANNING = "LOAD_ACCOMODATION_PLANNING";
export const REMOVE_ACCOMODATION = "REMOVE_ACCOMODATION";
export const UPDATE_SEARCH = "UPDATE_SEARCH";
export const LOAD_UNAVAILABLEDATES = "LOAD_UNAVAILABLEDATES";
export const SET_UNAVAILABLEDATES = "SET_UNAVAILABLEDATES";
