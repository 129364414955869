import {
  UPDATE_ACCOMODATION,
  SET_ACCOMODATION,
  SET_ACCOMODATIONS,
  LOAD_ACCOMODATIONS,
  SAVE_ACCOMODATION,
  SAVE_ACCOMODATION_PLANNING,
  LOAD_ACCOMODATION_PLANNING,
  REMOVE_ACCOMODATION,
  UPDATE_SEARCH,
  LOAD_UNAVAILABLEDATES,
  SET_UNAVAILABLEDATES,
} from "../actions/accomodations/types";

const today = new Date();
let minimumDate = new Date(
  today.getFullYear(),
  today.getMonth(),
  today.getDate(),
);
minimumDate.setDate(minimumDate.getDate() + 1);

export const initialState = {
  isFetching: false,
  accomodations: [],
  accomodationDetail: {},
  search: {
    checkin: undefined,
    checkinError: undefined,
    checkout: undefined,
    checkoutError: undefined,
    adults: 0,
    children: 0,
    minimumDate,
    nights: 0,
  },
  unavailableDates: [],
};

const accomodations = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_ACCOMODATION:
      return {
        ...state,
        accomodationDetail: {
          ...state.accomodationDetail,
          [action.field]: action.value,
        },
      };
    case SET_ACCOMODATION:
      return {
        ...state,
        isFetching: false,
        accomodationDetail: action.accomodationDetail,
      };
    case SET_ACCOMODATIONS:
      return {
        ...state,
        isFetching: false,
        accomodations: action.accomodations,
      };
    case SET_UNAVAILABLEDATES:
      return {
        ...state,
        isFetching: false,
        unavailableDates: action.unavailabledates,
      };
    case UPDATE_SEARCH:
      return {
        ...state,
        search: action.search,
      };
    case SAVE_ACCOMODATION:
      return { ...state, isFetching: action.isFetching || false };
    case REMOVE_ACCOMODATION:
      return { ...state, isFetching: action.isFetching || false };
    case SAVE_ACCOMODATION_PLANNING:
      return { ...state, isFetching: action.isFetching || false };
    case LOAD_ACCOMODATION_PLANNING:
      return { ...state, isFetching: action.isFetching || false };
    case LOAD_ACCOMODATIONS:
      return { ...state, isFetching: action.isFetching || false };
    case LOAD_UNAVAILABLEDATES:
      return { ...state, isFetching: action.isFetching || false };
    default:
      return state;
  }
};

export default accomodations;
