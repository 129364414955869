import { createUseStyles } from "react-jss";

const styles = createUseStyles({
  loader: {
    height: "100%",
    width: "100%",
    position: "fixed",
    top: 0,
    left: 0,
    backgroundColor: "rgba(0, 0, 0, 0.6)",
    zIndex: 9980,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  loaderBox: {
    padding: 30,
    backgroundColor: "white",
    boxShadow: "0 2rem 4rem rgba(0, 0, 0, 0.2)",
    borderRadius: 3,
  },

  loaderSpinner: {
    border: "8px solid #f3f3f3",
    borderTop: "8px solid #007aff",
    borderRadius: "50%",
    width: 48,
    height: 48,
    animationName: "$spin",
    animationDuration: "2s",
    animationTimingFunction: "linear",
    animationIterationCount: "infinite",
  },

  "@keyframes spin": {
    from: {
      transform: "rotate(0deg)",
    },
    to: {
      transform: "rotate(360deg)",
    },
  },
});

export default styles;
