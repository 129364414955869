import { combineReducers } from "redux";
import app from "./app";
import auth from "./auth";
import settings from "./settings";
import accomodations from "./accomodations";
import users from "./users";
import subscribers from "./subscribers";
import booking from "./booking";
import accomodationOptions from "./accomodationOptions";

const appReducer = combineReducers({
  app,
  auth,
  settings,
  accomodations,
  accomodationOptions,
  users,
  subscribers,
  booking,
});

const rootReducer = (state, action) => {
  if (action.type === "RESET_APP") {
    const { app, settings } = state;
    state = { app, settings };
  }
  return appReducer(state, action);
};

export default rootReducer;
