import {
  START_FETCHING,
  END_FETCHING,
  CHANGE_LOCALE,
  CLEAR_LOCALE,
  SHOW_SNACK,
  HIDE_SNACK,
  END_OFFLINE,
  START_OFFLINE,
} from "../actions/app/types";
import config from "../../config/app-config";

const initialSnack = {
  message: undefined,
  messageType: undefined,
  delay: undefined,
};

export const initialState = {
  isFetching: false,
  isOffline: !window.navigator.onLine,
  currentLocale: undefined,
  config: config,
  snack: {
    ...initialSnack,
  },
};

const app = (state = initialState, action) => {
  switch (action.type) {
    case START_FETCHING:
      return { ...state, isFetching: true };
    case END_FETCHING:
      return { ...state, isFetching: false };
    case CHANGE_LOCALE:
      return { ...state, currentLocale: action.i18n };
    case CLEAR_LOCALE:
      return { ...state, currentLocale: undefined };
    case START_OFFLINE:
      return { ...state, isOffline: true };
    case END_OFFLINE:
      return { ...state, isOffline: false };
    case SHOW_SNACK:
      return {
        ...state,
        snack: {
          message: action.message,
          messageType: action.messageType || null,
          delay: action.delay || null,
          i18n: action.i18n || null,
        },
      };
    case HIDE_SNACK:
      return {
        ...state,
        snack: { ...initialSnack },
      };
    default:
      return state;
  }
};

export default app;
